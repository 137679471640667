<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑设备"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="设备名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入设备名称' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>

        <a-form-item label="设备序列号">
          <a-input
            v-decorator="['no', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入设备序列号' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateEquipment } from '@/api/equipment'

export default {
  name: 'EditEquipment',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'equipment' }),
      submitting: false,
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.data = Object.assign({},
        {
          name: this.record.name,
          no: this.record.no,
        })
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateEquipment(this.record.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
